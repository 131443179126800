.game-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}

.back-button {
  background: none;
  border: none;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
}

.score-lives {
  text-align: right;
}

/* Standard Button Group Style */
.button-group {
  display: flex;
  justify-content: center; /* Center buttons horizontally */
  flex-wrap: wrap; /* Wrap buttons to the next line if necessary */
  gap: 15px; /* Space between buttons */
  margin-top: 150px; /* Space below heading */
}

/* Standard Button Style */
.button {
  font-family: 'Press Start 2P', cursive; /* Apply the same font */
  padding: 10px 20px; /* Button padding */
  color: white; /* Text color */
  background-color: rgba(0, 0, 0, 0.5); /* Slightly transparent background */
  border: 2px solid white; /* White border */
  border-radius: 8px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  min-width: 120px; /* Ensure consistent button size */
}

/* Button Hover Effect */
.button:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 15px rgba(255, 255, 255, 0.6); /* Add a glow effect */
}

/* Center and align input and buttons at the bottom */
.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0; /* Move to the bottom of the screen */
  width: 100%;
  text-align: center;
  padding: 10px 0; /* Add some vertical padding */
  background-color: rgba(0, 0, 0, 0.6); /* Slightly dark background for better visibility */
  gap: 15px; /* Space between input and buttons */
}

/* Styling for input box */
input[type="text"] {
  width: 200px;
  height: 40px;
  margin-right: 10px;
  font-family: 'Press Start 2P', cursive;
  padding: 5px;
  border: 2px solid white; /* Match button borders */
  border-radius: 8px; /* Match button corners */
  background-color: rgba(0, 0, 0, 0.5); /* Similar to button background */
  color: white;
}

/* Game Over Overlay */
.game-over-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 10;
}

.game-over-overlay .button {
  margin: 10px;
  padding: 15px 25px;
}

/* Style for the lives images */
.life-image {
  width: 30px; /* Adjust the width as needed */
  height: 30px; /* Adjust the height as needed */
  margin: 0 5px; /* Space between images */
}