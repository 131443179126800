/* Import the Press Start 2P font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

/* Apply the font to all elements */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Press Start 2P', cursive;
}

/* Standardized Page Container */
.page-container {
  color: white;
  height: 100vh;
  width: 100vw;
  background-image: url('../assets/images/backgrounds/bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

/* Header Style */
.header {
  position: absolute;
  top: 20px;
  width: 100%;
  display: flex;
  justify-content: center; /* Center the heading horizontally */
  align-items: center;
  padding: 0 20px;
}

/* Standard Heading Style */
.heading {
  color: white;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 10px; /* Ensure heading is centered in the header */
}

/* User Profile Icon */
.user-profile-icon {
  position: absolute;
  right: 20px; /* Position on the top right */
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
  transition: transform 0.2s ease;
}

.user-profile-icon:hover {
  transform: scale(1.1);
}

/* Popup Style */
.popup {
  color: white;
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  border: 2px solid white;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

.popup-button {
  background: none;
  border: none;
  color: white;
  font-family: 'Press Start 2P', cursive;
  cursor: pointer;
  margin: 5px 0;
  padding: 10px;
  text-align: left;
  transition: background-color 0.2s ease;
}

.popup-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Form Container for Login and SignUp pages */
.form-container {
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  border: 2px solid white;
  width: 420px;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}
/* Feedback Icon Style */
.feedback-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 24px;
  background-color: rgba(0, 0, 0, 0.33);
  color: white;
  border: 2px solid white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.3s ease;
}

.feedback-icon:hover {
  transform: scale(1.1);
  background-color: rgba(255, 255, 255, 0.7);
  color: black;
}

/* Feedback Box Style */
.feedback-box {
  position: fixed;
  bottom: 80px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.334);
  border: 2px solid white;
  border-radius: 10px;
  padding: 20px;
  width: 350px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  text-align: center;
}

.feedback-box h3 {
  color: white;
  font-size: 1.4rem;
  margin-bottom: 15px;
}

/* Centered Textarea for Feedback */
.feedback-box textarea {
  width: 95%;
  height: 100px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, 0.334);
  color: white;
  padding: 12px;
  font-family: 'Press Start 2P', cursive;
  font-size: 0.9rem;
  border-radius: 8px;
  resize: none;
  margin: 15px auto;
  display: block;
}

/* Centered Button */
.feedback-box button {
  background-color: rgba(0, 0, 0, 0.5);
  border: 2px solid white;
  color: white;
  cursor: pointer;
  padding: 10px 20px;
  font-family: 'Press Start 2P', cursive;
  transition: transform 0.2s ease;
  display: block;
  margin: 10px auto;
}

.feedback-box button:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.6);
}

/* Star Rating Style */
.star-rating {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.star {
  font-size: 28px;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: color 0.3s ease;
}

.star.filled {
  color: #FFD700; /* Gold color for filled stars */
}

.star.default-filled {
  color: #FFD700; /* Default first star filled */
}

.star:hover {
  color: #FFD700;
}
.feedback-box .thank-you-message {
  color: #f9f9f9; /* Green color for success */
  font-size: 1rem;
  font-family: 'Press Start 2P', cursive;
  margin-top: 15px; /* Spacing from other elements */
  text-align: center; /* Center-align the message */
}

.missed-words-overlay {
  position: fixed; /* Ensure it covers the entire viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9); /* Slightly darker background */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Higher than the game-over screen */
  color: white;
  padding: 20px;
}

.missed-words-overlay ul {
  list-style: none;
  padding: 0;
  max-height: 60%;
  overflow-y: auto;
  margin: 20px 0;
}

.missed-words-overlay li {
  margin: 5px 0;
}

.missed-words-overlay .button {
  margin-top: 20px;
}


.login-message {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-family: 'Press Start 2P', cursive;
  z-index: 1000;
  animation: fadeInOut 3s ease;
}

@keyframes fadeInOut {
  0%, 80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.signup-suggestion p {
  font-size: 0.9rem;
  color: white;
}

.signup-button {
  margin-top: 10px;
  padding: 8px 16px;
  font-family: 'Press Start 2P', cursive;
  background-color: rgba(0, 0, 0, 0.5);
  border: 2px solid white;
  color: white;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.signup-button:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.6);
}
.login-button {
  margin-bottom: 30px; /* Adjust spacing here */
}
/* Form Container for Login and SignUp pages */
.signup-form {
  background-color: rgba(0, 0, 0, 0.7); /* Transparent background */
  padding: 30px;
  width: 500px; /* Larger width for the form */
  text-align: center;
  border-radius: 10px;
  border: 2px solid white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.login-redirect {
  text-align: center;
  margin-top: 20px;
}

.login-redirect p {
  color: white;
  margin-bottom: 10px;
}

.login-redirect .button {
  font-family: 'Press Start 2P', cursive;
  padding: 10px 20px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border: 2px solid white;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.login-redirect .button:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 15px rgba(255, 255, 255, 0.6);
}

/* Transparent Dropdown Style */
.transparent-select {
  font-family: 'Press Start 2P', cursive;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, 0.3); /* Transparent background */
  color: white;
  border-radius: 5px;
  appearance: none;
}

/* Styling for Language Selection Buttons */
.language-selection {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 10px 0;
  color: white;
}

.language-selection button {
  font-family: 'Press Start 2P', cursive;
  padding: 10px 20px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border: 2px solid white;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.language-selection button:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.6);
}

.language-selection .selected {
  background-color: rgba(255, 215, 0, 0.7);
  border-color: #ffd700;
}

.character-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avatar-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}

.avatar-option-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.avatar-option {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 3px solid transparent;
  transition: transform 0.3s ease, border-color 0.3s ease;
}

.avatar-option:hover {
  transform: scale(1.1);
  border-color: #007bff;
}

input[type="radio"]:checked + .avatar-option {
  border-color: #007bff;
  transform: scale(1.2);
}

.avatar-label {
  margin-top: 10px;
  font-size: 16px;
  color: #fff;
}

input[type="radio"] {
  display: none;
}


.profmessage {
  font-size: 1.2rem;
  color: white;
  text-align: center;
  margin-top: 20px;
  line-height: 1.5;
}


/* Standard Input Fields */
input {
  font-family: 'Press Start 2P', cursive;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 5px;
  appearance: none; /* Ensures consistent styling across browsers */
}

input[type="date"] {
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}

input:focus {
  border-color: #00bfff;
  box-shadow: 0px 0px 10px #00bfff;
  outline: none;
}

/* Modal Overlay */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1001;
}

/* Modal Content */
.modal-content {
  background-color: rgba(0, 0, 0, 0.9);
  padding: 20px;
  border-radius: 10px;
  width: 320px;
  text-align: center;
  border: 2px solid white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}

.modal-content h2 {
  color: white;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

/* Input Fields inside Modal */
.modal-content input {
  font-family: 'Press Start 2P', cursive;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 5px;
}

.modal-content input:focus {
  border-color: #00bfff;
  box-shadow: 0px 0px 10px #00bfff;
  outline: none;
}

/* Modal Buttons */
.modal-content button {
  font-family: 'Press Start 2P', cursive;
  background-color: rgba(0, 0, 0, 0.5);
  border: 2px solid white;
  padding: 8px 16px;
  margin-top: 10px;
  color: white;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.modal-content button:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.6);
}

/* Error Message in Modal */
.error-message {
  color: red;
  font-size: 0.8rem;
  margin-bottom: 10px;
}

/* Standard Button Group Style */
.button-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 150px;
}

/* Standard Button Style */
.button {
  font-family: 'Press Start 2P', cursive;
  padding: 10px 20px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border: 2px solid white;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.button:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 15px rgba(255, 255, 255, 0.6);
}

/* Arrow Button Style */
.arrow-button {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 2rem;
  color: white;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  transition: transform 0.2s ease;
}

.arrow-button:hover {
  transform: scale(1.1);
  box-shadow: 0px 4px 10px rgba(255, 255, 255, 0);
}

/* Input Fields Style */
input, textarea {
  font-family: 'Press Start 2P', cursive;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border: 2px solid white;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  width: 300px;
}

input:focus, textarea:focus {
  outline: none;
  border-color: #00bfff;
  box-shadow: 0px 0px 10px #00bfff;
}

/* Responsive Design for Small Screens */
@media (max-width: 768px) {
  .heading {
    font-size: 1.2rem;
    top: 10px;
  }
  .button {
    font-size: 1rem;
    padding: 8px 16px;
  }
  input, textarea {
    width: 90%;
  }
  .button-group {
    margin-top: 50px;
  }
  .arrow-button {
    font-size: 1.5rem;
  }
  .user-profile-icon {
    width: 30px;
    height: 30px;
  }
}

/* Profile Container */
.profile-container {
  max-width: 500px;
  width: 100%;
  text-align: left;
}

.profile-info p {
  color: white;
  font-size: 1rem;
  margin: 10px 0;
}

.settings h3 {
  color: white;
  margin-top: 20px;
}

.settings .button {
  margin-top: 10px;
}

/* Adjust for consistency on smaller screens */
@media (max-width: 768px) {
  input {
    width: 90%;
  }
}


/* Custom categories container */
.categories-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 99%; /* Keeps a compact size */
}

/* Individual category row */
.category-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.3);
  padding: 8px 12px;
  margin-bottom: 5px; /* Reduced spacing between items */
  border-radius: 5px;
  border: 2px solid white;
}

/* Expand button */
.expand-button {
  background: none;
  border: none;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  margin-right: 8px;
}

/* Set name */
.set-name {
  flex: 1;
  color: white;
  font-size: 1rem;
  text-align: left;
  padding-left: 5px;
}

/* Buttons for delete and play */
.button {
  font-size: 0.9rem;
  padding: 5px 10px;
  color: white;
  background: rgba(0, 0, 0, 0.7);
  border: 2px solid white;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 5px;
  transition: transform 0.2s ease;
}

.button:hover {
  transform: scale(1.05);
}

.word-pairs-container {
  max-height: 500px; /* Adjust height based on font size */
  overflow-y: auto; /* Enables scrolling */
  border: 2px solid white;
  border-radius: 5px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  scrollbar-width: thin;
  scrollbar-color: white transparent;
}



/* Ensures the list is visually consistent */
.word-pairs-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.word-pair {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.33);
}

.word-pair span {
  flex: 1;
  text-align: left;
}

.word-pair input {
  width: 40%;
  padding: 5px;
  font-size: 0.9rem;
}


/* Sleek Scrollbar */
.word-pairs-container::-webkit-scrollbar {
  width: 8px;
}

.word-pairs-container::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 10px;
}

.word-pairs-container::-webkit-scrollbar-track {
  background: transparent;
}


.delete-word {
  background: none;
  border: none;
  color: red;
  font-size: 1.2rem;
  cursor: pointer;
  margin-left: 10px;
}

.delete-word:hover {
  color: white;
}
/* Edit and Save Buttons Alignment */
.word-pair-actions {
  display: flex;
  justify-content: flex-end; /* Moves buttons to the right */
  gap: 10px;
  padding-top: 10px;
}
.button {
  padding: 8px 14px;
  font-size: 0.8rem;
}

.add-word,
.save-button{
  font-size: 0.8rem;
  padding: 6px 12px;
  border-radius: 5px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.7);
  border: 2px solid white;
  color: white;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.add-word:hover,
.save-button:hover{
  transform: scale(1.05);
  box-shadow: 0px 2px 5px rgba(255, 255, 255, 0.5);
}


/* Floating Add Button */
.floating-add-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 2rem;
  border: none;
  cursor: pointer;
  background: #FFD700;
  color: black;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.floating-add-button:hover {
  background-color: white;
  color: black;
}

/* Close Form Button */
.close-form {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Add set form */
.add-set-form {
  position: fixed;
  bottom: 70px;
  right: 20px;
  background: rgba(0, 0, 0, 0.9);
  padding: 20px;
  border-radius: 10px;
  width: 320px;
  border: 2px solid white;
  z-index: 1000;
}

/* Input inside form */
.add-set-form input {
  font-family: 'Press Start 2P', cursive;
  width: 100%;
  padding: 8px;
  background: rgba(0, 0, 0, 0.5);
  border: 2px solid white;
  color: white;
  margin-top: 5px;
}
/* New Custom Set Form Styles */
.new-set-form {
  position: absolute;
  bottom: 60px; /* Positioned near the + button */
  right: 20px; /* Positioned near the right corner */
  background: rgba(0, 0, 0, 0.8);
  border: 2px solid white;
  padding: 15px;
  border-radius: 10px;
  width: 600px;
  max-height: 600px; /* Restrict height */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* Close button for the form */
.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.close-form-button {
  background: none;
  border: none;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.close-form-button:hover {
  transform: scale(1.1);
}

.word-pairs-scroll-container {
  max-height: 200px; /* Space for 5 word pairs */
  overflow-y: auto; /* Enable scrolling */
  width: 100%;
  padding-right: 5px; /* Prevent content from sticking */
}

/* Custom Scrollbar */
.word-pairs-scroll-container::-webkit-scrollbar {
  width: 6px;
}

.word-pairs-scroll-container::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
}

.word-pair-actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}


.new-set-form h3 {
  color: white;
  font-size: 1.5rem;
  margin-bottom: 15px;
}

/* Input Fields */
.new-set-form input {
  width: 90%;
  padding: 10px;
  margin: 10px 0;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 5px;
  font-size: 1rem;
}


/* Responsive styles */
@media (max-width: 768px) {
  .heading {
    font-size: 1.2rem;
  }
  .category-item {
    flex-wrap: wrap;
  }
  .button {
    font-size: 0.8rem;
    padding: 4px 8px;
  }
  .word-pair input {
    width: 45%;
  }
}